import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// States
import Auth from "./auth";
import actionsList from "./actionsList/index";
import priorityServices from "./priorityServices";

const authPersistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const actionsListPersisConfig = {
  key: "actionsList",
  storage,
  whitelist: ["actionsList"],
};

const priorityServicesPersisConfig = {
  key: "priorityServices",
  storage,
  whitelist: ["priorityServices"],
};

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authPersistConfig, Auth),
    actionsList: persistReducer(actionsListPersisConfig, actionsList),
    priorityServices: persistReducer(
      priorityServicesPersisConfig,
      priorityServices
    ),
  });

export default reducers;
