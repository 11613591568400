export const LOGIN_USER = "@auth/login_user";
export const UPDATE_AUTH_USER = "@auth/update_auth_user";
export const UPDATE_LOAD_USER = "@auth/update_load_user";
export const SEND_FORGET_PASSWORD_EMAIL = "@auth/send_forget_password_email";

// ActionsList
export const INIT_DATA = "@actionsList/init_data";
export const LOADER = "@actionsList/loader";
export const MOVE_DIFF_CONTAINER = "@actionsList/moveDiffContainer";
export const MOVE_SAME_CONTAINER = "@actionsList/moveSameContainer";

//Priority Service
export const INIT_PRIORITY_SERVICES = "@priorityServices/initialData";
export const UPDATE_RANK_PRIORITY_SERVICES = "@priorityServices/update_rank";
