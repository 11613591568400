import { useState } from 'react';

export default function useSafeLocalStorage(key, initialValue) {
  const [valueProxy, setValueProxy] = useState(() => {
    const value = window.localStorage.getItem(key);

    return value ? JSON.parse(value) : initialValue;
  });

  const setValue = (value) => {
    window.localStorage.setItem(key, value);
    setValueProxy(value);
  };

  return [valueProxy, setValue];
}
