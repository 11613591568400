import React from "react";
import PropTypes from "prop-types";

export default function SubMenuNavigation({ items }) {
  return (
    <div className="mt-20 p-6">
      <ul className="text-primary">
        {items.map((item) => (
          <li key={item.name} className="mt-4 mb-4">
            {item.subCategory ? (
              <div>
                <h4>{item.name}</h4>
                <ul>
                  {item.subCategory.map((sub) => (
                    <li key={sub.name} className="mt-2 mb-2">
                      <a
                        className="text-primary opacity-30 ml-4"
                        href={sub.href}
                      >
                        {sub.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <a href={item.href}>{item.name}</a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

SubMenuNavigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
};
