import {
  INIT_DATA,
  MOVE_SAME_CONTAINER,
  MOVE_DIFF_CONTAINER,
  LOADER,
} from "constants/ActionTypes";

import INIT_STATE from "./initState";

// Internal Helper functions
import { moveDiffContainer } from "./moveDiffContainer";
import { moveSameContainer } from "./moveSameContainer";

export default function actionsListReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_DATA: {
      const { actionsList, isPending, error } = payload;
      return { ...state, actionsList, isPending, error };
    }

    case MOVE_SAME_CONTAINER: {
      const { values, isPending, error } = payload;
      const actionsList = moveSameContainer(values, state);

      return { ...state, actionsList, isPending, error };
    }

    case MOVE_DIFF_CONTAINER: {
      const { values, isPending, error } = payload;
      const actionsList = moveDiffContainer(values, state);

      return { ...state, actionsList, isPending, error };
    }

    case LOADER: {
      return { ...state, isPending: payload };
    }

    default:
      return state;
  }
}
