export function moveDiffContainer(values, state) {
  const { arrayActions, actionItem, hoverIndex } = values;

  const oldGroupA = state.actionsList.GROUP_A;
  const oldGroupB = state.actionsList.GROUP_B;
  const oldGroupC = state.actionsList.GROUP_C;
  const oldGroupD = state.actionsList.GROUP_D;

  let newGroupA = [];
  let newGroupB = [];
  let newGroupC = [];
  let newGroupD = [];

  const fromPriorityGroup = actionItem.priority_group;
  const destPriorityGroup = arrayActions[0].priority_group;
  const currentIndex = actionItem.priority_rank - 1;

  if (fromPriorityGroup === "A") {
    let newRank = currentIndex + 1;
    newGroupA = oldGroupA.filter((action) => action.id !== actionItem.id);
    newGroupA = newGroupA.map((item) => {
      if (item.priority_rank > newRank) {
        item.priority_rank = newRank;
        newRank++;
      }
      return item;
    });

    if (destPriorityGroup === "B") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupB = [...oldGroupB];
      newGroupB.splice(hoverIndex, 0, actionItem);

      newGroupB = newGroupB.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "C") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupC = [...oldGroupC];
      newGroupC.splice(hoverIndex, 0, actionItem);

      newGroupC = newGroupC.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "D") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupD = [...oldGroupD];
      newGroupD.splice(hoverIndex, 0, actionItem);

      newGroupD = newGroupD.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
  }

  if (fromPriorityGroup === "B") {
    let newRank = currentIndex + 1;
    newGroupB = oldGroupB.filter((action) => action.id !== actionItem.id);
    newGroupB = newGroupB.map((item) => {
      if (item.priority_rank > newRank) {
        item.priority_rank = newRank;
        newRank++;
      }
      return item;
    });

    if (destPriorityGroup === "A") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupA = [...oldGroupA];
      newGroupA.splice(hoverIndex, 0, actionItem);

      newGroupA = newGroupA.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "C") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupC = [...oldGroupC];
      newGroupC.splice(hoverIndex, 0, actionItem);

      newGroupC = newGroupC.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "D") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupD = [...oldGroupD];
      newGroupD.splice(hoverIndex, 0, actionItem);

      newGroupD = newGroupD.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
  }

  if (fromPriorityGroup === "C") {
    let newRank = currentIndex + 1;
    newGroupC = oldGroupC.filter((action) => action.id !== actionItem.id);
    newGroupC = newGroupC.map((item) => {
      if (item.priority_rank > newRank) {
        item.priority_rank = newRank;
        newRank++;
      }
      return item;
    });

    if (destPriorityGroup === "A") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupA = [...oldGroupA];
      newGroupA.splice(hoverIndex, 0, actionItem);

      newGroupA = newGroupA.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "B") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupB = [...oldGroupB];
      newGroupB.splice(hoverIndex, 0, actionItem);

      newGroupB = newGroupB.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "D") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupD = [...oldGroupD];
      newGroupD.splice(hoverIndex, 0, actionItem);

      newGroupD = newGroupD.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
  }

  if (fromPriorityGroup === "D") {
    let newRank = currentIndex + 1;
    newGroupD = oldGroupD.filter((action) => action.id !== actionItem.id);
    newGroupD = newGroupD.map((item) => {
      if (item.priority_rank > newRank) {
        item.priority_rank = newRank;
        newRank++;
      }
      return item;
    });

    if (destPriorityGroup === "A") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupA = [...oldGroupA];
      newGroupA.splice(hoverIndex, 0, actionItem);

      newGroupA = newGroupA.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "B") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupB = [...oldGroupB];
      newGroupB.splice(hoverIndex, 0, actionItem);

      newGroupB = newGroupB.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
    if (destPriorityGroup === "C") {
      let newRank = hoverIndex + 1;

      actionItem.priority_rank = newRank;
      actionItem.priority_group = destPriorityGroup;
      // Change other fields...

      newGroupC = [...oldGroupC];
      newGroupC.splice(hoverIndex, 0, actionItem);

      newGroupC = newGroupC.map((item) => {
        if (item.priority_rank >= newRank && item.id !== actionItem.id) {
          item.priority_rank = newRank + 1;
          newRank++;
        }
        return item;
      });
    }
  }

  if (newGroupA.length === 0) newGroupA = [...oldGroupA];
  if (newGroupB.length === 0) newGroupB = [...oldGroupB];
  if (newGroupC.length === 0) newGroupC = [...oldGroupC];
  if (newGroupD.length === 0) newGroupD = [...oldGroupD];

  return {
    GROUP_A: newGroupA,
    GROUP_B: newGroupB,
    GROUP_C: newGroupC,
    GROUP_D: newGroupD,
  };
}
