import React from 'react';

export default function Tachometer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.5" height="21.5" viewBox="0 0 40.5 31.5">
      <path
        fill="currentColor"
        d="M20.25 2.25a20.249 20.249 0 00-17.5 30.431 2.225 2.225 0 001.926 1.069h31.148a2.225 2.225 0 001.929-1.069A20.249 20.249 0 0020.25 2.25zm0 4.5a2.22 2.22 0 012.132 1.663 3.79 3.79 0 00-.243.469l-.648 1.946a2.192 2.192 0 01-1.24.423 2.25 2.25 0 010-4.5zM6.75 27A2.25 2.25 0 119 24.75 2.25 2.25 0 016.75 27zm3.375-11.25a2.25 2.25 0 112.25-2.25 2.25 2.25 0 01-2.25 2.25zm17.351-5.091L23.164 23.6a4.416 4.416 0 01.962 5.654h-7.752a4.454 4.454 0 013.587-6.721l4.314-12.942a1.687 1.687 0 013.2 1.068zm1.031 4.022l1.093-3.273a2.218 2.218 0 01.777-.157 2.25 2.25 0 110 4.5 2.216 2.216 0 01-1.868-1.07zM33.75 27A2.25 2.25 0 1136 24.75 2.25 2.25 0 0133.75 27z"
        data-name="Icon awesome-tachometer-alt"
        transform="translate(0 -2.25)"
      />
    </svg>
  );
}
