import INIT_STATE from "./initState";
import {
  INIT_PRIORITY_SERVICES,
  UPDATE_RANK_PRIORITY_SERVICES,
} from "constants/ActionTypes";
import updatePriorityRankServices from "./updatePriorityRankServices";

export default function priorityServices(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_PRIORITY_SERVICES: {
      return payload;
    }

    case UPDATE_RANK_PRIORITY_SERVICES: {
      const { serviceToUpdate, isPending, error } = payload;

      const priorityServices = updatePriorityRankServices(
        serviceToUpdate,
        state
      );

      return { ...state, priorityServices, isPending, error };
    }

    default:
      return state;
  }
}
