export default function updatePriorityRankServices(serviceToUpdate, state) {
  const { id } = serviceToUpdate;
  const oldServices = state.priorityServices;

  const newServices = oldServices.map((service) => {
    if (service.id === id) {
      return { ...serviceToUpdate, priority_rank_pending: true };
    } else {
      return service;
    }
  });

  return newServices;
}
