import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useNavigation(navigation) {
  const location = useLocation();
  const [subMenu, setSubMenu] = useState([]);
  const [newNavigation, setNewNavigation] = useState(navigation);

  const findSubMenu = () => {
    const { pathname } = location;
    const currentSubMenu = navigation.find((item) => item.href === pathname);

    if (currentSubMenu && currentSubMenu.subMenu) {
      setSubMenu(currentSubMenu.subMenu);
    } else {
      setSubMenu([]);
    }
  };

  const findActiveNav = () => {
    const updatedNavigation = newNavigation.map((item) => {
      if (location.pathname === item.href) {
        return {
          ...item,
          current: true,
        };
      }

      return {
        ...item,
        current: false,
      };
    });
    setNewNavigation(updatedNavigation);
  };

  useEffect(() => {
    findSubMenu();
    findActiveNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    subMenu,
    newNavigation,
    setSubMenu,
  };
}
