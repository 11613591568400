/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import "./scroll.css";

// Custom Icons
import Tachometer from "../../icons/Tachometer";
import MessageIcon from "../../icons/MessageIcon";
import CogIconSett from "../../icons/CogIconSett";
import { LogoSm } from "../../icons/LogoSm";

// In-House Components
import SideBarNavigation from "../../components/SideBarNavigation";
import MobileNavigation from "../../components/MobileNavigation";
import SubMenuNavigation from "../../components/SubMenuNavigation";
import ThemeToggle from "../../components/ThemeToggle";

// Hooks
import useNavigation from "../../hooks/useNavigation";

const navigationList = [
  {
    name: "Home",
    // href: "/dashboard/cloud",
    href: "/dashboard/AwsDevOps",
    icon: Tachometer,
    current: true,
    subMenu: [
      {
        name: "SaaS Cockpits",
        href: "",
      },
      {
        name: "Enterprise Cockpits",
        href: "",
        subCategory: [
          {
            name: "+ Add New",
            href: "",
          },
        ],
      },
      {
        name: "Cockpit Central",
        href: "https://preview.cloudcockpit.io/",
        externalLink: true
      },
      {
        name: "My Cockpit",
        href: "/dashboard/awsdevops",
      },
    ],
  },
  {
    name: "Settings",
    // href: "/dashboard/settings",
    href: "/dashboard/awsdevops",
    icon: CogIconSett,
    current: false,
    subMenu: [
      {
        name: "SaaS Cockpits",
        href: "",
        subCategory: [
          {
            name: "+ Add New",
            href: "",
          },
        ],
      },
      {
        name: "Enterprise Cockpits",
        href: "",
        subCategory: [
          {
            name: "+ Add New",
            href: "",
          },
        ],
      },
      {
        name: "Security",
        href: "",
        subCategory: [
          {
            name: "API Keys",
            href: "",
          },
          {
            name: "OAuth Clients",
            href: "",
          },
        ],
      },
    ],
  },
  {
    name: "Support",
    href: "#",
    icon: MessageIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { subMenu, newNavigation, setSubMenu } = useNavigation(navigationList);
  const [showSubMenuNav, setShowSubMenuNav] = useState();

  return (
    <div className="h-screen flex overflow-hidden">
      {/* SideBar Navigation */}
      <SideBarNavigation
        handleMenu={setSubMenu}
        items={newNavigation}
        classNames={classNames}
        setSubMenuHover={setShowSubMenuNav}
      />
      {/* Mobile menu */}
      <MobileNavigation
        show={mobileMenuOpen}
        toggleMenu={setMobileMenuOpen}
        items={navigationList}
        classNames={classNames}
      />
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden h-screen">
        {/* Main content */}
        <div className="flex-1 flex overflow-hidden">
          {showSubMenuNav !== undefined && (
            <main
              className={
                showSubMenuNav
                  ? `w-0 lg:w-2/12 flex overflow-y-auto bg-secondary rounded-r-2xl border-l border-gray-200 opacity-100 animate-fadeIn`
                  : `opacity-0 animate-fadeOut w-0 flex overflow-y-auto bg-secondary rounded-r-2xl border-l border-gray-200`
              }
            >
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="min-w-full lg:min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last border rounded-r-2xl border-green-default relative"
              >
                {/* Your content */}
                <picture className="absolute top-4 left-1/3 ">
                  <LogoSm />;
                </picture>
                <SubMenuNavigation items={subMenu} handleEvent={setSubMenu} />
                <button
                  className="absolute left-3/4 top-8 text-primary animate-bounce"
                  onClick={(e) => setShowSubMenuNav(!showSubMenuNav)}
                >
                  <svg
                    version="1.1"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="#2BA444"
                  >
                    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                  </svg>
                </button>
                <picture className=" text-primary text-center w-full absolute bottom-8">
                  {"<< LOGO >>"}
                </picture>
              </section>
            </main>
          )}

          {/* Secondary column (hidden on smaller screens) */}
          <aside className="flex-grow w-full lg:w-11/12 overflow-y-auto h-screen max-h-screen flex flex-col scroll-aside">
            <header className="w-full lg:p-2">
              <div className="relative z-10 flex-shrink-0 h-11 dark:bg-black-200 bg-green-100 border-b border-gray-200 shadow-sm flex">
                <button
                  type="button"
                  className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 flex justify-between px-4 sm:px-6">
                  <div className="flex-1 flex items-center">
                    <h2 className="text-blue-light">AWS DevOps Cockpit</h2>
                  </div>
                  <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                    <ThemeToggle />
                  </div>
                </div>
              </div>
            </header>
            {/* Your content */}
            {children}
          </aside>
        </div>
      </div>
    </div>
  );
}
