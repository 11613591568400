import React from "react";
import { Switch, Route } from "react-router";

// Pages
import Dashboard from "./Dashboard";
import Login from "./Login";

import { ProtectedRoute, UnprotectedRoute } from "./routeCheck";

export const RouteWrapper = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const Routes = () => (
  <Switch>
    <UnprotectedRoute
      loggedInPath="/dashboard"
      path="/"
      exact
      component={Login}
    />
    <ProtectedRoute path="/dashboard" component={Dashboard} />
  </Switch>
);
export default Routes;

// import React from "react";
// import { Switch, Route } from "react-router";

// // Pages
// import Dashboard from "./Dashboard";
// import Login from "./Login";
// import { ProtectedRoute, UnprotectedRoute } from "./routeCheck";
// import Signin from "./AUTH/signin";
// import PreSignin from "./AUTH/preSignin";

// export const RouteWrapper = ({
//   component: Component,
//   layout: Layout,
//   ...rest
// }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) => (
//         <Layout {...props}>
//           <Component {...props} />
//         </Layout>
//       )}
//     />
//   );
// };

// console.log(window.location.href);
// var url_string = window.location.href;
// console.log(url_string);
// var url = new URL(url_string);
// const email = url.searchParams.get("a");
// const password = url.searchParams.get("b");
// localStorage.setItem("EMT_UserEmail", email);
// localStorage.setItem("EMT_UserPassword", password);

// const Routes = () => (
//   <Switch>
//     <UnprotectedRoute
//       loggedInPath="/dashboard"
//       path="/"
//       exact
//       component={Login}
//     />
//     <ProtectedRoute path="/dashboard" component={Dashboard} />
//     <Route path="/signin" component={Signin} />
//     <Route path="/presignin" component={PreSignin} />
//   </Switch>
// );
// export default Routes;
