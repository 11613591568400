import React from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// Redux
import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";

//Router
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router-dom";
import Routes from "./routes";

import "./App.css";

export const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Routes />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
