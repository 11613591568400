import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function SideBarNavigation({
  items,
  classNames,
  setSubMenuHover,
}) {
  return (
    <div className="hidden w-20 bg-third overflow-y-auto md:block">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {items.map((item) => {
            if(item.externalLink){
              return (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-white text-black-200"
                      : "hover:bg-white hover:text-black text-black-200",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-black"
                        : "text-primary group-hover:text-black",
                      "h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </a>
              )
            };

            return (
              <Link
                key={item.name}
                to={item.href}
                onClick={() => setSubMenuHover(true)}
                className={classNames(
                  item.current
                    ? "bg-white text-black-200"
                    : "hover:bg-white hover:text-black text-black-200",
                  "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-black"
                      : "text-primary group-hover:text-black",
                    "h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  );
}

SideBarNavigation.propTypes = {
  classNames: PropTypes.func.isRequired,
  // handleMenu: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  setSubMenuHover: PropTypes.func,
};
