import { Amplify } from "aws-amplify";

export default function amplifyConfigure() {
  let configAPI = {
    aws_project_region: "us-east-1",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "API_KEY",
  };
  const {
    NODE_ENV,
    REACT_APP_DEV_ENDPOINT,
    REACT_APP_DEV_API_KEY,
    REACT_APP_PROD_ENDPOINT,
    REACT_APP_PROD_API_KEY,
  } = process.env;

  if (NODE_ENV === "development")
    configAPI = {
      ...configAPI,
      aws_appsync_graphqlEndpoint: REACT_APP_DEV_ENDPOINT,
      aws_appsync_apiKey: REACT_APP_DEV_API_KEY,
    };

  if (NODE_ENV === "production") {
    configAPI = {
      ...configAPI,
      aws_appsync_graphqlEndpoint: REACT_APP_PROD_ENDPOINT,
      aws_appsync_apiKey: REACT_APP_PROD_API_KEY,
    };
  }

  Amplify.configure(configAPI);
}
