export function moveSameContainer(values, state) {
  const { actionItem, dragIndex, hoverIndex } = values;
  const priorityGroup = actionItem.priority_group;

  const oldGroupA = state.actionsList.GROUP_A;
  const oldGroupB = state.actionsList.GROUP_B;
  const oldGroupC = state.actionsList.GROUP_C;
  const oldGroupD = state.actionsList.GROUP_D;

  let newGroupA = [];
  let newGroupB = [];
  let newGroupC = [];
  let newGroupD = [];

  if (priorityGroup === "A") {
    newGroupA = [...oldGroupA];
    let newRankIterable = 1;

    newGroupA.splice(dragIndex, 1);
    newGroupA.splice(hoverIndex, 0, actionItem);

    newGroupA = newGroupA.map((item) => {
      item.priority_rank = newRankIterable;
      newRankIterable++;
      return item;
    });
  }

  if (priorityGroup === "B") {
    newGroupB = [...oldGroupB];
    let newRankIterable = 1;

    newGroupB.splice(dragIndex, 1);
    newGroupB.splice(hoverIndex, 0, actionItem);

    newGroupB = newGroupB.map((item) => {
      item.priority_rank = newRankIterable;
      newRankIterable++;
      return item;
    });
  }

  if (priorityGroup === "C") {
    newGroupC = [...oldGroupC];
    let newRankIterable = 1;

    newGroupC.splice(dragIndex, 1);
    newGroupC.splice(hoverIndex, 0, actionItem);

    newGroupC = newGroupC.map((item) => {
      item.priority_rank = newRankIterable;
      newRankIterable++;
      return item;
    });
  }

  if (priorityGroup === "D") {
    newGroupD = [...oldGroupD];
    let newRankIterable = 1;

    newGroupD.splice(dragIndex, 1);
    newGroupD.splice(hoverIndex, 0, actionItem);

    newGroupD = newGroupD.map((item) => {
      item.priority_rank = newRankIterable;
      newRankIterable++;
      return item;
    });
  }

  if (newGroupA.length === 0) newGroupA = [...oldGroupA];
  if (newGroupB.length === 0) newGroupB = [...oldGroupB];
  if (newGroupC.length === 0) newGroupC = [...oldGroupC];
  if (newGroupD.length === 0) newGroupD = [...oldGroupD];

  return {
    GROUP_A: newGroupA,
    GROUP_B: newGroupB,
    GROUP_C: newGroupC,
    GROUP_D: newGroupD,
  };
}
