import React, { useState } from "react";
import useDarkMode from "../../hooks/userDarkMode";

export default function ThemeToggle() {
  const [isDark, setIsDark] = useDarkMode();
  const [isChecked, setIsChecked] = useState(true);

  return (
    <div className="theme-toggle text-white flex items-center justify-center gap-2">
      <span className="text-xs">Dark Theme</span>
      <div className="m-1 flex items-center justify-start">
        <div className=" relative inline-block w-10 mr-2 align-middle select-none transition duration-500 ease-in">
          <input
            type="checkbox"
            name="toggleTheme"
            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            onChange={() => {
              setIsDark(!isDark);
              setIsChecked(!isChecked);
            }}
            checked={isChecked}
          />
          <label
            htmlFor="toggleTheme"
            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-dark cursor-pointer"
          ></label>
        </div>
      </div>
    </div>
  );
}
