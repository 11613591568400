import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import PropTypes from "prop-types";
import Dashboard from "../../layouts/Dashboard";
import { RouteWrapper } from "../";

const PageLoader = () => <div>Loading...</div>;

const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, "");
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>

        {/* -- */}
        <Redirect
          exact
          from={`${requestedUrl}/`}
          to={`${requestedUrl}/awsdevops`}
        />
        <RouteWrapper
          layout={Dashboard}
          path={`${requestedUrl}/awsdevops/:subsection?`}
          component={lazy(() => import("./AwsDevOps"))}
        />
        {/* -- */}

        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

Dashboards.propTypes = {
  // match: PropTypes.objectOf.isRequired,
  match: PropTypes.any.isRequired,
};

export default Dashboards;
